import { render, staticRenderFns } from "./CommonOutgoingCard.vue?vue&type=template&id=06f80f4f"
import script from "./CommonOutgoingCard.vue?vue&type=script&lang=js"
export * from "./CommonOutgoingCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports